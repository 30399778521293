import { observer } from "mobx-react-lite";
import { WrapperForRequests } from "../components/request/WrapperForRequests";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Chip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import "../components/request/RequestMessage.css"
import { BASE_URL, faqInitialState } from "../constants";
import Cookies from 'js-cookie';

export const FAQ = observer(() => {
    const [faq, setFaq] = useState([]);
    const [modal, setModal] = useState(false);
    const [newFaq, setNewFaq] = useState(faqInitialState);

    useEffect(() => {
        const csrfToken = Cookies.get('csrftoken');
        const headers = {};
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/bot/faq`, {
                    headers: headers
                });
                setFaq(response.data);
            } catch (error) {
                console.error("Error fetching FAQ data:", error);
            }
        };

        fetchData();
    }, []);

    const createMarkup = (htmlString) => {
        const sanitizedString = htmlString.replace(/\n/g, '<br />');
        return { __html: sanitizedString };
    };

    const handleDeleteFaq = async (id) => {
        const csrfToken = Cookies.get('csrftoken');
        const headers = {};
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        try {
            await axios.delete(`${BASE_URL}/bot/faq/${id}`, {
                headers: headers
            });
            setFaq((prevFaq) => prevFaq.filter((faq) => faq.id !== id));
        } catch (error) {
            console.error("Error deleting FAQ:", error);
        }
    };

    const handleAddFaq = async () => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const headers = {};
            if (csrfToken) {
                headers['X-CSRFToken'] = csrfToken;
            }
            const response = await axios.post(`${BASE_URL}/bot/faq`, newFaq, {
                headers: headers
            });
            setFaq((prevFaq) => [...prevFaq, response.data.data]);
            setModal(false);
            setNewFaq({ name_ru: "", description_ru: "", name_en: "", description_en: "" });
        } catch (error) {
            console.error("Error adding FAQ:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewFaq((prevFaq) => ({ ...prevFaq, [name]: value }));
    };

    return (
        <WrapperForRequests title="Мои FAQ">
            <div className="cont">
                <Button variant="contained" color="primary" onClick={() => setModal(true)}>Добавить FAQ</Button>
            </div>
            <Dialog open={modal} onClose={() => setModal(false)}>
                <DialogTitle>Добавить FAQ</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Пожалуйста, введите название и описание сначало на Русском, после на Английском
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name_ru"
                        label="Вопрос(RU)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newFaq.name_ru}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="description_ru"
                        label="Ответ(RU)"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        value={newFaq.description_ru}
                        onChange={handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name_en"
                        label="Вопрос(EN)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newFaq.name_en}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="description_en"
                        label="Ответ(EN)"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                        value={newFaq.description_en}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModal(false)}>Отмена</Button>
                    <Button onClick={handleAddFaq}>Добавить</Button>
                </DialogActions>
            </Dialog>
            <div className="carts">
                {faq.map((respons) => (
                    <div className="car" key={respons.id}>
                        <Chip label={"Часто задаваемый вопрос №" + respons.id} color="success" />
                        <div className="cart">
                            <Typography variant="h5">{respons.name_ru}</Typography>
                            <div className="h3" dangerouslySetInnerHTML={createMarkup(respons.description_ru)} />
                            <Typography variant="h5">{respons.name_en}</Typography>
                            <div className="h3" dangerouslySetInnerHTML={createMarkup(respons.description_en)} />
                        </div>
                        <div className="delete">
                            <Button variant="contained" color="error" onClick={() => handleDeleteFaq(respons.id)}>
                                Удалить
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </WrapperForRequests>
    );
});
