const host = process.env?.REACT_APP_BACKEND_URL;

export const BASE_URL = `${host}/v1`;
export const BASE_MEDIA = `${host}`;
export const URL = `${host}`;

export const IMAGE_URL = `${BASE_URL}/user`;
export const MEDIA_URL = `${BASE_URL}/user`;

export const WS_URL = process.env?.REACT_APP_WS_URL;

export const NO_PHOTO = "https://www.interra-rus.com/storage/media/default.png";

export const faqInitialState = {
    description_ru: "", 
    description_en: "" ,
    name_ru: "",
    name_en: "", 
}