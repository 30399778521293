import { Button, Container, Divider, Paper, Stack, Typography, } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import webSocketStore from "../store/WebSocketStore";
import { useRouterStore } from "mobx-state-router";
import { RequestCard } from "../components/request/RequestCard";
import { RequestChat } from "../components/request/RequestChat";
import { RequestStatusEnum } from "../api/UserApi";
import { useSnackbar } from "notistack";
import { BinarySocketMsgType } from "../services/BinarySocketService";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Autocomplete } from "@mui/material";
import "../components/request/RequestMessage.css"
import Cookies from 'js-cookie';
import axios from "axios";
import { BASE_URL } from "../constants";

export const RequestView = observer(() => {
    const routerStore = useRouterStore();
    const { params } = routerStore.routerState;
    const requestId = parseInt(params.id);
    const { enqueueSnackbar } = useSnackbar();
    const [ModalPanel, setModalPanel] = useState(false)
    const [admins, setAdmins] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState({ "id": 0, "t_first_name": "Выберите", "t_last_name": "админа" })
    const [close, setClose] = useState(false)
    const callbackEndChat = (data) => {
    enqueueSnackbar("Вы завершили чат!", {
        variant: "success",
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }
    });
}

    useEffect(() => {
        webSocketStore.openConnection(requestId);
        const csrfToken = Cookies.get('csrftoken');
        const headers = {};
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        const fetchData = async (requestId) => {
            try {
                const response = await axios.get(`${BASE_URL}/user/admin`, {
                    headers: headers
                });
                const response2 = axios.post(`${BASE_URL}/user/request/view/${requestId}`,{},{
                    headers: headers
                });
                setAdmins(response.data);
            } catch (error) {
                console.error("Error fetching FAQ data:", error);
            }
        };

        fetchData(requestId);
    }, []);

    const handleEndChat = () => {
        webSocketStore.binarySocketService.sendEndChat();
        setClose(false)
        webSocketStore.addOneTimeMessageHandler(BinarySocketMsgType.END_CHAT, callbackEndChat)
    }

    if (!webSocketStore.request) return (
        <Container>
            <Paper>
                <Stack direction="row" p={1} spacing={2}>
                    <Container sx={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <Stack p={1} spacing={2}>
                            <Typography variant="h5">Загрузка...</Typography>
                        </Stack>
                    </Container>
                </Stack>
            </Paper>
        </Container>
    )


    const handleRequestChange = (id, id_chat) => {
        const csrfToken = Cookies.get('csrftoken');
        const headers = {};
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        const fetchData = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/user/admin/change/${id}/${id_chat}`, {
                    headers: headers
                });
                setModalPanel(false)
                window.location.href = '/my-requests';
                webSocketStore.binarySocketService.sendMessageToUser("По вашей заявке был сменен ответсвенный Администратор/Hr сотрудник, это может увеличить время ответа на ваш вопрос\n\nНапоминаю что наша служба технической поддержки работает с 8:00 до 21:00 по московскому времени. Мы обязательно ответим на ваши вопросы.");
            } catch (error) {
                console.error("Error fetching FAQ data:", error);
            }
        };

        fetchData();
    }

    return (

        <Container>
            <RequestCard request={webSocketStore.request} detail bottom={<>
                <Divider />
                <RequestChat />
            </>}>
                <Container>
                    <Dialog open={ModalPanel} onClose={() => setModalPanel(false)}>
                        <DialogTitle>Передача заявки</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Выберите из списка администраторов/Hr сотрудников того кому вы хотите передать данную заявку
                            </DialogContentText>
                            <Autocomplete
                                options={admins}
                                getOptionLabel={(admins) => admins.t_first_name + " " + admins.t_last_name}
                                value={selectedAdmin}
                                onChange={(event, newValue) => setSelectedAdmin(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Выберите администратора/HR"
                                        variant="standard"
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setModalPanel(false)}>Отмена</Button>
                            <Button onClick={() => handleRequestChange(selectedAdmin.id, requestId)}>Отправить</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={close} onClose={() => setClose(false)}>
                        <DialogTitle>Подтвердите действие</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Вы уверены, что хотите закончить чат? Это действие нельзя будет отменить.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setClose(false)} color="primary">
                                Отмена
                            </Button>
                            <Button onClick={handleEndChat} color="error" variant="contained">
                                Закончить чат
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="display">
                        {
                            webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS &&
                            <>
                            <Button variant="contained" onClick={() => setClose(true)}>
                                Закончить чат
                            </Button>

                            <div className="button_hr">
                            <Button variant="contained" color="error" onClick={() => setModalPanel(true)}>
                                Передать заявку
                            </Button>
                            </div>
                            </>
                        }
                    </div>
                </Container>
            </RequestCard>
        </Container>
    )
})