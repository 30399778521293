import { IconButton, Stack, Typography } from "@mui/material";
import { Cached } from "@mui/icons-material";
import { observer } from "mobx-react";

export const WrapperForRequests = observer(({ title, onClickUpdate, children }) => {
    return (
        <Stack spacing={2} sx={{
            padding: 2,
        }}>
            <Stack direction="row" spacing={1}>
                <Typography variant="h4">{title}</Typography>
                <IconButton
                    onClick={onClickUpdate}
                >
                    <Cached />
                </IconButton>
            </Stack>
            {children}
        </Stack>
    )
})
