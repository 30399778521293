import {makeAutoObservable} from "mobx";

class AppStore {
    imageUrlModal = null;

    constructor() {
        makeAutoObservable(this)
    }

    setImageUrlModal(url) {
        this.imageUrlModal = url;
    }
}

const appStore = new AppStore();
export default appStore;
