import { Pagination, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import userStore from "../store/UserStore";
import { useEffect, useState } from "react";
import { RequestCard } from "../components/request/RequestCard";
import { AcceptInJobActions } from "../components/request/AcceptInJobActions";
import { WrapperForRequests } from "../components/request/WrapperForRequests";
import UserApi from "../api/UserApi";
import { WS_URL } from "../constants";


export const Home = observer(() => {
    const [token, setToken] = useState("");

    async function fetchData() {
        const user = await UserApi.me();

        const token = user.config.headers.Authorization.slice(6);
        setToken(token);
    }

    useEffect(() => {
        fetchData();
        const socket = new WebSocket(`${WS_URL}/ws/requests?token=${token}`);

        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            userStore.handleWebSocketEvent(data);
        };
        return () => {
            // Убираем обработчик при размонтировании компонента
            socket.onmessage = null;
        };
    }, [token]);

    return (
        <WrapperForRequests
            title="Новые заявки"
            onClickUpdate={() => {
                userStore.updateRequests();
            }}
        >
            <Internal />
        </WrapperForRequests>
    );
});

const Internal = observer(() => {
    const list = userStore.requests;
    const [page, setPage] = useState(1);
    const perPage = 5;
    const totalPages = Math.ceil(list.length / perPage);

    const filteredList = list.slice((page - 1) * perPage, page * perPage);

    return (
        <>
            <Typography>{
                userStore.isLoadingRequest ? 'Загрузка...'
                    : !userStore.isLoggedIn ? 'Вы не авторизованы'
                        : userStore.requests.length === 0 ? 'У вас нет заявок'
                            : ''
            }</Typography>
            <Stack spacing={2}>
                {filteredList.map((request) => (
                    <RequestCard key={request.id} request={request}>
                        <AcceptInJobActions requestId={request.id} />
                    </RequestCard>
                ))}
            </Stack>
            <Pagination count={totalPages} page={page} onChange={(event, value) => setPage(value)} />
        </>
    );
});
