import {
    Button,
    Typography,
    Container,
    Stack,
    Paper,
    Divider,
} from "@mui/material";
import { observer } from "mobx-react";
import { useRouterStore } from "mobx-state-router";
import { RoutesEnum } from "../router";

export const NotFound = observer(() => {
    const routerStore = useRouterStore();

    return (
        <Container>
            <Paper>
                <Stack direction="row" p={1} spacing={2}>
                    <Container sx={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <Stack p={1} spacing={2}>
                            <Typography variant="h5">404</Typography>
                            <Typography>Страница не найдена</Typography>
                        </Stack>
                    </Container>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} p={2}>
                    <Button variant="contained" onClick={() => routerStore.goTo(RoutesEnum.HOME)}>
                        На главную
                    </Button>
                </Stack>
            </Paper>
        </Container>
    )
});