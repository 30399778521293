import { Button, Container, Stack } from "@mui/material";
import { useRouterStore } from "mobx-state-router";
import { RoutesEnum } from "../../router";
import userStore from "../../store/UserStore";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react";
import { BASE_URL } from "../../constants";
import { useState } from "react";
import UserApi from "../../api/UserApi";
import { useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { set } from "mobx";

export const AcceptInJobActions = observer(({ requestId }) => {
    const [role, setRole] = useState("")
    const [close, setClose] = useState(false)
    const [closehr, setCloseHr] = useState(false)

    async function fetchData() {
        const user = await UserApi.me();
        setRole(user.data.role);
    }

    useEffect(() => {
        fetchData();
    }, []);


    const routerStore = useRouterStore();
    const { enqueueSnackbar } = useSnackbar();

    const handleAcceptClick = async () => {
        await userStore.acceptRequest(requestId);
        enqueueSnackbar("Вы успешно приняли заявку", { variant: "success" });
        userStore.updateAllRequests();
        await routerStore.goTo(RoutesEnum.REQUEST_VIEW, { params: { id: requestId.toString() } });
    }

    const handleDeclineClick = async () => {
        await userStore.declineRequest(requestId);
        enqueueSnackbar("Вы успешно отклонили заявку", { variant: "success" });
        userStore.updateRequests();
        userStore.updateMyRequests();
        await routerStore.goTo(RoutesEnum.HOME);
        setClose(false)
    }

    const handleChangeClick = async (e) => {
        e.preventDefault()
        await fetch(`${BASE_URL}/bot/request/${Number(requestId)}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            }
        })
        enqueueSnackbar("Вы успешно отправили заявку Hr сотрудникам", { variant: "success" });
        userStore.updateRequests();
        userStore.updateMyRequests();
        setCloseHr(false)
    }

    return (
        <>
        <Dialog open={close} onClose={() => setClose(false)}>
                <DialogTitle>Подтвердите действие</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите отклонить заявку? Это действие нельзя будет отменить.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setClose(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleDeclineClick} color="error" variant="contained">
                        Отклонить
                    </Button>
                </DialogActions>
            </Dialog>
        <Dialog open={closehr} onClose={() => setCloseHr(false)}>
                <DialogTitle>Подтвердите действие</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите передать заявку Hr Сотрудникам? Это действие нельзя будет отменить.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCloseHr(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleChangeClick} color="error" variant="contained">
                        Передать
                    </Button>
                </DialogActions>
            </Dialog>
        <Stack direction="row" spacing={2}>
            <Button variant="contained" sx={{ width: '23%' }} onClick={handleAcceptClick}>
                Принять
            </Button>
            <Button variant="contained" sx={{ width: '26%' }} onClick={() => setClose(true)} color="error">
                Отклонить
            </Button>
            {role === 'admin' && <Button
                variant="contained"
                sx={{ width: '32%' }}
                onClick={() => setCloseHr(true)}
                color="error">
                Отправить HR
            </Button>}
        </Stack>
        </>
    )
})