import {makeAutoObservable, runInAction} from "mobx";
import {BinarySocketMsgType, BinarySocketService} from "../services/BinarySocketService";
import userStore from "./UserStore";
import errorStore from "./ErrorStore";
import {RequestStatusEnum} from "../api/UserApi";

class WebSocketStore {
    isSocketConnect = false;

    requestId = null;
    request = null;

    constructor() {
        makeAutoObservable(this);

        this.binarySocketService = new BinarySocketService();
        this.binarySocketService.addMessageHandler(BinarySocketMsgType.SYNCHRONIZE, this.onSynchronize);
        this.binarySocketService.addMessageHandler(BinarySocketMsgType.NEW_MESSAGE, this.onNewMessage);
        this.binarySocketService.addMessageHandler(BinarySocketMsgType.END_CHAT, this.onEndChat);
        this.binarySocketService.addMessageHandler(BinarySocketMsgType.ERROR, this.onError);

        this.stackMessagesRef = null;
    }

    addOneTimeMessageHandler = (type, callback) => {
        this.binarySocketService.addOneTimeMessageHandler(type, callback);
    }

    openConnection = (requestId) => {
        this.binarySocketService.openConnection({
            requestId,
            token: userStore.token,
            callback: () => runInAction(() => {
                this.isSocketConnect = true;
                this.requestId = requestId;
            }),
        })
    }

    closeConnection = () => {
        this.binarySocketService.closeConnection(
            () => runInAction(() => {
                this.isSocketConnect = false;
                this.requestId = null;
                this.request = null;
            })
        );
    }

    onSynchronize = (data) => {
        runInAction(() => {
            this.request = data;
        });
    }

    onNewMessage = (data) => {
        runInAction(() => {
            this.request.messages.push(data);
            // ToDo: большущий костыль, но я фиг знает как исправить адекватно
            setTimeout(() => {
                this.stackMessagesRef?.current?.scrollIntoView({behavior: 'smooth', block: "end", inline: "nearest"});
            })
        });
    }

    onEndChat = (data) => {
        runInAction(() => {
            this.request.status = RequestStatusEnum.DONE;
        });
        userStore.updateMyRequests();
    }



    setIsSocketConnect(isSocketConnect) {
        this.isSocketConnect = isSocketConnect;
    }
}

const webSocketStore = new WebSocketStore();
export default webSocketStore;
