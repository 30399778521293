import { Button, Chip, Container, Divider, Paper, Stack, Typography } from "@mui/material";
import { formatTgUser } from "../../utils/tgUser";
import { IMAGE_URL } from "../../constants";
import { formatCustomDate } from "../../utils/date";
import appStore from "../../store/AppStore";
import { observer } from "mobx-react";
import { RequestStatusEnum, translateStatus, translateType } from "../../api/UserApi";


const chipByStatus = {
    [RequestStatusEnum.NEW]: <Chip label={translateStatus[RequestStatusEnum.NEW]} color="info" />,
    [RequestStatusEnum.IN_PROGRESS]: <Chip label={translateStatus[RequestStatusEnum.IN_PROGRESS]} color="primary" />,
    [RequestStatusEnum.DECLINED]: <Chip label={translateStatus[RequestStatusEnum.DECLINED]} color="error" />,
    [RequestStatusEnum.DONE]: <Chip label={translateStatus[RequestStatusEnum.DONE]} color="success" />,
}


export const RequestCard = observer(({ request, children, detail, bottom }) => {
    return <Paper key={request.id}>
        <Stack direction="row" p={1} spacing={2}>
            <Container sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <Stack p={1} spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h5">Заявка №{request.id}</Typography>
                        {
                            detail && chipByStatus[request.status]
                        }
                        {request.is_trans ? <Chip label={"Переданная заявка"} color="error" /> : <></>}
                        {request.is_view ? <></>: <Chip label={"!!!Новое сообщение!!!"} color="success" /> }
                    </Stack>
                    {formatTgUser(request.user)}
                    <Typography>Тема: {translateType[request.type] || "Новая заявка"}</Typography>
                    <Typography>Описание: {request.text || "без описания"}</Typography>
                    {request.document &&
                        <Button
                            variant="outlined"
                            onClick={() => {
                                window.open(`${IMAGE_URL}${request.document}`)
                            }}
                        >
                            Скачать документ
                        </Button>

                    }
                    <Typography>Создана: {request.created_at ? formatCustomDate(request.created_at) : "Только что"}</Typography>
                    {request.accepted_at ? <Typography>Принята в работу: {formatCustomDate(request.accepted_at)}</Typography> : <p></p>}
                    {request.completed_at ? <Typography>Вопрос закрыт: {formatCustomDate(request.completed_at)}</Typography> : <p></p>}
                </Stack>
            </Container>
            {request.image &&
                <>
                    <Divider orientation="vertical" flexItem />
                    <Container sx={{
                        padding: 1,
                        cursor: "pointer",
                    }}
                        onClick={() => {
                            appStore.setImageUrlModal(`${IMAGE_URL}${request.image}`)
                        }}>
                        <img
                            src={`${IMAGE_URL}${request.image}`}
                            alt="Изображение"
                            style={{
                                width: "100%",
                                maxHeight: 400,
                                objectFit: "contain",
                            }}
                        />
                    </Container>
                </>
            }
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} p={2}>
            {children}
        </Stack>
        {bottom}
    </Paper>;
})
