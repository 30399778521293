import {Avatar, Stack, Typography, Link} from "@mui/material";

export function formatTgUser(user) {
    const text = `${user.t_first_name} ${user.t_last_name}`;

    let prefix = null;
    if (user.t_username) {
        prefix = <Link href={`https://t.me/${user.t_username}`} target="_blank" rel="noreferrer" sx={{
            textDecoration: "none",
            fontSize: "0.9rem",
        }}>@{user.t_username}</Link>;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar />
            <Stack>
                <Typography>{text}</Typography>
                {prefix}
            </Stack>
        </Stack>
    )
}

export function textFormatTgUser(user) {
    let text = `${user.t_first_name} ${user.t_last_name}`.trim();
    if (user.t_username) {
        text += `(@${user.t_username})`;
    }
    return text;
}