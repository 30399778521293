const STORAGE_KEY = 'token';

export const getAccessTokenFromLocalStorage = () => {
    return localStorage.getItem(STORAGE_KEY);
};

export const setAccessTokenToLocalStorage = (token) => {
    localStorage.setItem(STORAGE_KEY, token);
}

export const removeAccessTokenFromLocalStorage = () => {
    localStorage.removeItem(STORAGE_KEY);
}
