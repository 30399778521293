import { Box, IconButton, Modal } from "@mui/material";
import appStore from "../../store/AppStore";
import { Close } from "@mui/icons-material";
import { observer } from "mobx-react";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const ImageModal = observer(() => {
    return (
        <Modal
            open={appStore.imageUrlModal !== null}
            onClose={() => {
                appStore.setImageUrlModal(null)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <IconButton aria-label="Закрыть" onClick={() => {
                    appStore.setImageUrlModal(null)
                }}>
                    <Close />
                </IconButton>
                <img
                    src={appStore.imageUrlModal}
                    alt="Изображение"
                    style={{
                        width: "100%",
                    }}
                />
            </Box>
        </Modal>
    )
})