import { observer } from "mobx-react";
import { Box, CircularProgress } from "@mui/material";
import userStore from "../../store/UserStore";

export const LoadingModal = observer(() => {
    if (
        !userStore.isLogging &&
        userStore.isInit &&
        !userStore.isLoadingRequest &&
        !userStore.isLoadingMyRequest &&
        !userStore.isSendingAcceptRequest &&
        !userStore.isLoadingMe
    ) return null;

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 99999,
            }}
        >
            <CircularProgress />
        </Box>
    )
})
